import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    /* eslint-disable @typescript-eslint/no-empty-function */

    disableLogging() {
        if (environment.production) {
            console.warn = (): void => {};
            console.log = (): void => {};
            console.debug = (): void => {};
            console.warn = (): void => {};
            console.info = (): void => {};
        }
    }
}
