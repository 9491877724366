import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private translate: TranslateService,
        private primeNgConfig: PrimeNGConfig,
        private logging: LoggingService
    ) {
        this.initTranslationService();
        this.logging.disableLogging();
    }

    ngOnInit() {
        this.primeNgConfig.ripple = true;
    }

    protected initTranslationService() {
        this.translate.setDefaultLang('de');
        this.translate.use('de');
    }
}
