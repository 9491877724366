import { Injectable } from '@angular/core';
import { MessagesService } from '@services/messages.service';

@Injectable({
    providedIn: 'root'
})
export class BaseErrorHandler {
    constructor(protected messageService: MessagesService) {}

    showApiError(errorMessage: string) {
        const message = `ERROR_MESSAGES.${errorMessage}`.toUpperCase();
        this.messageService.showError(message);
    }

    showClientError(errorMessage: string) {
        const message = `ERROR_MESSAGES.${errorMessage}`.toUpperCase();
        this.messageService.showError(message);
    }
}
