import { NgModule } from '@angular/core';
import { provideRouter, RouterModule, Routes, withComponentInputBinding } from '@angular/router';
import { DashboardLayoutComponent } from '@app/layout';
import { UiDemoComponent } from '@layout/ui-demo/ui-demo.component';
import { AuthGuard } from '@core/guards';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'customers',
        pathMatch: 'full'
    },
    {
        path: '',
        component: DashboardLayoutComponent,
        children: [
            {
                path: 'benefits',
                loadChildren: () => import('@modules/benefit/benefit.module').then((m) => m.BenefitModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'customers',
                loadChildren: () => import('@modules/customer/customer.module').then((m) => m.CustomerModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'dashboard',
                loadChildren: () => import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'employee',
                loadChildren: () => import('@modules/employee/employee.module').then((m) => m.EmployeeModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'user',
                loadChildren: () => import('@modules/user/user.module').then((m) => m.UserModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'ui-demo',
                component: UiDemoComponent
            }
        ]
    },
    {
        path: 'auth',
        loadChildren: () => import('@modules/auth/auth.module').then((m) => m.AuthModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [provideRouter(routes, withComponentInputBinding())]
})
export class AppRoutingModule {}
