import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessagesService } from '@services/messages.service';
import { APIErrors, AuthErrors, TokenInterceptorMessages } from '@shared/enum';
import { BaseErrorHandler } from '@core/interceptors/base.error-handler';
import { SessionModel } from '@shared/models';
import { AuthService } from '@services/auth.service';
import { NavigationService } from '@core/services';

@Injectable({
    providedIn: 'root'
})
export class ErrorCatchingInterceptor extends BaseErrorHandler implements HttpInterceptor {
    constructor(
        protected override messageService: MessagesService,
        private authService: AuthService,
        protected navigationService: NavigationService
    ) {
        super(messageService);
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 500:
                        return this.handleApiError(request, next, error);
                    case 400:
                        return this.handleBadRequest(request, next, error);
                    case 401:
                        return this.handleUnauthorized(request, next, error);
                    case 404:
                        return this.handleNotFound(request, next, error);
                    default:
                        return next.handle(request).pipe(retry(0));
                }
            })
        );
    }

    // 400
    protected handleBadRequest(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        const errorCases = [TokenInterceptorMessages.TokenMissing, TokenInterceptorMessages.TokenFormatWrong];
        if (errorCases.includes(error.error)) return this.handleRemoveSession(error);
        return throwError(() => new Error(error.error));
    }

    // 401
    protected handleUnauthorized(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        if (error.error === TokenInterceptorMessages.SessionExpired) return this.tryToRefresh(request, next, error);
        if (error.error === TokenInterceptorMessages.NoSessionForToken) return this.handleRemoveSession(error);
        if (error.error === AuthErrors.Wrong_Credentials) this.showApiError(error.error);
        return throwError(() => new Error(error.error));
    }

    // 403
    protected handleForbidden(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        return throwError(() => new Error(error.error));
    }

    // 404
    protected handleNotFound(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        this.showApiError(APIErrors.GENERAL);
        return throwError(() => new Error(error.error));
    }

    private handleApiError(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        this.showApiError(APIErrors.GENERAL);
        return throwError(() => new Error(error.error));
    }

    private handleRemoveSession(error: HttpErrorResponse) {
        this.authService.removeSession();
        this.navigationService.goToLogin();
        return throwError(() => new Error(error.message));
    }

    private tryToRefresh(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        this.authService.sessionRefresh().subscribe({
            next: (data: SessionModel) => this.authService.storeSession(data),
            error: () => this.handleRemoveSession(error)
        });
        return next.handle(request);
    }
}
